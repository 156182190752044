<template>
  <div class="corneaOrder">
    <div class="top">
      <div >产品名称</div>
      <div>
        <div class="yj">
          <p>OD</p>
          <el-select class="srs" v-model="postData.S_OP_Json.CL_Order_ProductName_OD" placeholder="请选择">
            <el-option
                v-for="item in cpmc"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="yj">
          <p>OS</p>
          <el-select class="srs" v-model="postData.S_OP_Json.CL_Order_ProductName_OD" placeholder="请选择">
            <el-option
                v-for="item in cpmc"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="padding-left: 20px;">
        <div style="padding-bottom: 10px;" @click="$router.push({path: '/corneaContact/jqcorneaOrder/yanpian'})"><el-button>验片</el-button></div>
<!--        <div style="padding-bottom: 10px;"><el-button>验片</el-button></div>-->
        <div @click="$router.push({path: '/corneaContact/jqcorneaOrder/jiaopi'})"><el-button>交片</el-button></div>
      </div>
    </div>

    <div class="cf">
      <h3>处方</h3>
      <div class="srA">
        <div>
          <ul class="content-sr padding-20">
            <li></li>
            <li>DS</li>
            <li>DC</li>
            <li>X</li>
            <li>FK</li>
            <li>SK</li>
            <li>VA</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input @change="zh('CL_Order_OD_DS', 1)" v-model="postData.S_OP_Json.CL_Order_OD_DS" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_DC', 1)" v-model="postData.S_OP_Json.CL_Order_OD_DC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_X')" v-model="postData.S_OP_Json.CL_Order_OD_X" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_FK')" v-model="postData.S_OP_Json.CL_Order_OD_FK" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_SK')" v-model="postData.S_OP_Json.CL_Order_OD_SK" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_VA')" v-model="postData.S_OP_Json.CL_Order_OD_VA" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr padding-20">
            <li>OS</li>
            <li><el-input @change="zh('CL_Order_OS_DS', 1)" v-model="postData.S_OP_Json.CL_Order_OS_DS" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_DC', 1)" v-model="postData.S_OP_Json.CL_Order_OS_DC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_X')" v-model="postData.S_OP_Json.CL_Order_OS_X" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_FK')" v-model="postData.S_OP_Json.CL_Order_OS_FK" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_SK')" v-model="postData.S_OP_Json.CL_Order_OS_SK" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_VA')" v-model="postData.S_OP_Json.CL_Order_OS_VA" placeholder="请输入内容"></el-input></li>
          </ul>
        </div>
        <el-button @click="tb" class="last" type="primary">同步数据</el-button>
      </div>
    </div>

    <div class="cf">
<!--      <h3>参数</h3>-->
      <div class="srA dx">
        <div>
          <div v-if="postData.S_OP_Json.CL_Order_Type !== 'CRT'">
            <ul class="content-sr padding-20">
              <li></li>
              <li>试戴标准片组型号</li>
              <li>最终定制标准片组型号</li>
              <li>定位弧</li>
              <li>降幅</li>
              <li>直径</li>
              <li>环曲面</li>
            </ul>
            <ul class="content-sr">
              <li>OD</li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OD_type1"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in sdbz"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OD_type2"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in sdbz"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li><el-input @change="zh('CL_Order_OD_DCurve')" v-model="postData.S_OP_Json.CL_Order_OD_DCurve" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OD_JCurve')" v-model="postData.S_OP_Json.CL_Order_OD_JCurve" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OD_DIA')" v-model="postData.S_OP_Json.CL_Order_OD_DIA" placeholder="请输入内容"></el-input></li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OD_HuanQuMian"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in hqm"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
            </ul>
            <ul class="content-sr padding-20">
              <li>OS</li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OS_type1"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in sdbz"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OS_type2"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in sdbz"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li><el-input @change="zh('CL_Order_OS_DCurve')" v-model="postData.S_OP_Json.CL_Order_OS_DCurve" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OS_JCurve')" v-model="postData.S_OP_Json.CL_Order_OS_JCurve" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OS_DIA')" v-model="postData.S_OP_Json.CL_Order_OS_DIA" placeholder="请输入内容"></el-input></li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OS_HuanQuMian"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in hqm"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
            </ul>
          </div>

          <div v-if="postData.S_OP_Json.CL_Order_Type === 'CRT'">
            <ul class="content-sr padding-20">
              <li></li>
              <li>编号</li>
              <li>BC</li>
              <li>RZD1</li>
              <li>RZD2</li>
              <li>LZA1</li>
              <li>LZA2</li>
            </ul>
            <ul class="content-sr">
              <li>OD</li>
              <li>
                <el-input @change="zh('CL_Order_OD_type1')" v-model="postData.S_OP_Json.CL_Order_OD_type1" placeholder="请输入内容"></el-input>
              </li>
              <li>
                <el-input @change="zh('CL_Order_OD_type2')" v-model="postData.S_OP_Json.CL_Order_OD_type2" placeholder="请输入内容"></el-input>
              </li>
              <li><el-input @change="zh('CL_Order_OD_DCurve')" v-model="postData.S_OP_Json.CL_Order_OD_DCurve" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OD_JCurve')" v-model="postData.S_OP_Json.CL_Order_OD_JCurve" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OD_DIA')" v-model="postData.S_OP_Json.CL_Order_OD_DIA" placeholder="请输入内容"></el-input></li>
              <li>
                <el-input @change="zh('CL_Order_OD_HuanQuMian')" v-model="postData.S_OP_Json.CL_Order_OD_HuanQuMian" placeholder="请输入内容"></el-input>
              </li>
            </ul>
            <ul class="content-sr padding-20">
              <li>OS</li>
              <li>
                <el-input @change="zh('CL_Order_OS_type1')" v-model="postData.S_OP_Json.CL_Order_OS_type1" placeholder="请输入内容"></el-input>
              </li>
              <li>
                <el-input @change="zh('CL_Order_OS_type2')" v-model="postData.S_OP_Json.CL_Order_OS_type2" placeholder="请输入内容"></el-input>
              </li>
              <li><el-input @change="zh('CL_Order_OS_DCurve')" v-model="postData.S_OP_Json.CL_Order_OS_DCurve" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OS_JCurve')" v-model="postData.S_OP_Json.CL_Order_OS_JCurve" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OS_DIA')" v-model="postData.S_OP_Json.CL_Order_OS_DIA" placeholder="请输入内容"></el-input></li>
              <li>
                <el-input @change="zh('CL_Order_OS_HuanQuMian')" v-model="postData.S_OP_Json.CL_Order_OS_HuanQuMian" placeholder="请输入内容"></el-input>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div class="srA dx">
        <div>
          <ul class="content-sr padding-20">
            <li></li>
            <li v-if="postData.S_OP_Json.CL_Order_Type == 'CRT'">power</li>
            <li v-if="postData.S_OP_Json.CL_Order_Type == 'CRT'">DIA</li>
            <li v-if="postData.S_OP_Json.CL_Order_Type !== 'CRT'">颜色</li>
            <li v-if="postData.S_OP_Json.CL_Order_Type !== 'CRT'">镜片材料</li>
            <li style="width: calc(30em + .8rem)!important;">特殊镜片定制</li>
            <li>试戴验光</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li>
              <el-select  v-if="postData.S_OP_Json.CL_Order_Type !== 'CRT'" v-model="postData.S_OP_Json.CL_Order_OD_Color"  filterable allow-create  placeholder="请选择">
                <el-option
                    v-for="item in ys"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-input v-if="postData.S_OP_Json.CL_Order_Type == 'CRT'" @change="zh('CL_Order_OD_Color')" v-model="postData.S_OP_Json.CL_Order_OD_Color" placeholder="请输入内容"></el-input>
            </li>
            <li>
              <el-select v-if="postData.S_OP_Json.CL_Order_Type !== 'CRT'" v-model="postData.S_OP_Json.CL_Order_OD_material"  filterable allow-create  placeholder="请选择">
                <el-option
                    v-for="item in jpcl"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-input v-if="postData.S_OP_Json.CL_Order_Type == 'CRT'" @change="zh('CL_Order_OD_material')" v-model="postData.S_OP_Json.CL_Order_OD_material" placeholder="请输入内容"></el-input>
            </li>
            <li style="width: calc(30em + .8rem)!important;"><el-input @change="zh('CL_Order_OD_special')" v-model="postData.S_OP_Json.CL_Order_OD_special" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_wearGlassesRef')" v-model="postData.S_OP_Json.CL_Order_OD_wearGlassesRef" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr padding-20">
            <li>OS</li>
            <li>
              <el-select v-if="postData.S_OP_Json.CL_Order_Type !== 'CRT'" v-model="postData.S_OP_Json.CL_Order_OS_Color"  filterable allow-create  placeholder="请选择">
                <el-option
                    v-for="item in ys"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-input v-if="postData.S_OP_Json.CL_Order_Type == 'CRT'" @change="zh('CL_Order_OS_Color')" v-model="postData.S_OP_Json.CL_Order_OS_Color" placeholder="请输入内容"></el-input>
            </li>
            <li>
              <el-select v-if="postData.S_OP_Json.CL_Order_Type !== 'CRT'" v-model="postData.S_OP_Json.CL_Order_OS_material"  filterable allow-create  placeholder="请选择">
                <el-option
                    v-for="item in jpcl"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-input v-if="postData.S_OP_Json.CL_Order_Type == 'CRT'" @change="zh('CL_Order_OS_material')" v-model="postData.S_OP_Json.CL_Order_OS_material" placeholder="请输入内容"></el-input>
            </li>
            <li style="width: calc(30em + .8rem)!important;"><el-input @change="zh('CL_Order_OS_special')" v-model="postData.S_OP_Json.CL_Order_OS_special" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_wearGlassesRef')" v-model="postData.S_OP_Json.CL_Order_OS_wearGlassesRef" placeholder="请输入内容"></el-input></li>
          </ul>
        </div>
      </div>
      <div class="srA dx" v-if="postData.S_OP_Json.CL_Order_Type !== 'CRT' && (postData.S_OP_Json.CL_Order_OD_type1 === 'DV' || postData.S_OP_Json.CL_Order_OS_type1 === 'DV' ) ">
        <div>
          <div>
            <ul class="content-sr padding-20">
              <li></li>
              <li>调节因子</li>
              <li>小光学区SOZ</li>
              <li>超薄设计THIN</li>
            </ul>
            <ul class="content-sr">
              <li>OD</li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OD_adjustFactor"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in tjyz"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OD_SOZ"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in SOZ"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OD_THIN"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in SOZ"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
            </ul>
            <ul class="content-sr padding-20">
              <li>OS</li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OS_adjustFactor"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in tjyz"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OS_SOZ"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in SOZ"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li>
                <el-select v-model="postData.S_OP_Json.CL_Order_OS_THIN"  filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in SOZ"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="time">
      <p>定片时间</p>
      <el-date-picker
          class="rq mag-7"
          v-model="postData.S_OP_Json.CL_Order_OrderDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
      <p>取镜时间</p>
      <el-date-picker
          class="rq"
          v-model="postData.S_OP_Json.CL_Order_TakeDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
    </div>

    <div class="bottom">
      <el-button @click="$router.back(-1)">返回</el-button>
      <el-button @click="save" type="primary">保存</el-button>
      <el-button @click="ysqm" type="primary">医生签名</el-button>
    </div>
  </div>
</template>

<script>

import pf from '@/publicFn/baseFn'
import axios from "@/api/axios"
import { Loading } from 'element-ui';
export default {
  name: "JQcorneaOrder",
  data() {
    return {
      value: '',
      value1: '',
      value2: '',
      tjyz: [],
      SOZ: [
        {
          value: '是',
          label: '是'
        },
        {
          value: '否',
          label: '否'
        },
        {
          value: '',
          label: ''
        },
      ],
      cplx: [
        {
          value: '0',
          label: 'VST'
        },
        {
          value: '1',
          label: 'CRT'
        },
        {
          value: '2',
          label: 'RGP'
        },
        {
          value: '3',
          label: 'Misight'
        }
      ],
      cpmc:[
      ],
      cpmcdata: [],
      postData: {
        S_OP_ChooseId: "",
        S_OP_CustomerId: "",
        S_OP_ExpertId: "00000000-0000-0000-0000-000000000000",
        S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json: {
          CL_Order_ChooseId: "",
          CL_Order_OD_Color: "",
          CL_Order_OD_DC: "",
          CL_Order_OD_DCurve: "",
          CL_Order_OD_DIA: "",
          CL_Order_OD_DS: "",
          CL_Order_OD_FK: "",
          CL_Order_OD_HuanQuMian: "",
          CL_Order_OD_JCurve: "",
          CL_Order_OD_SK: "",
          CL_Order_OD_VA: "",
          CL_Order_OD_X: "",
          CL_Order_OD_material: "",
          CL_Order_OD_special: "",
          CL_Order_OD_type1: "",
          CL_Order_OD_type2: "",
          CL_Order_OS_Color: "",
          CL_Order_OS_DC: "",
          CL_Order_OS_DCurve: "",
          CL_Order_OS_DIA: "",
          CL_Order_OS_DS: "",
          CL_Order_OS_FK: "",
          CL_Order_OS_HuanQuMian: "",
          CL_Order_OS_JCurve: "",
          CL_Order_OS_SK: "",
          CL_Order_OS_VA: "",
          CL_Order_OS_X: "",
          CL_Order_OS_material: "",
          CL_Order_OS_special: "",
          CL_Order_OS_type1: "",
          CL_Order_OS_type2: "",
          CL_Order_OrderDate: "",
          CL_Order_ProductId_OD: "",
          CL_Order_ProductId_OS: "",
          CL_Order_ProductName_OD: "",
          CL_Order_ProductName_OS: "",
          CL_Order_TakeDate: "",
          CL_Order_Type: "",
          CL_Order_OD_wearGlassesRef: '',
          CL_Order_OS_wearGlassesRef: '',
          CL_Order_OD_adjustFactor: '',
          CL_Order_OS_adjustFactor: '',
          CL_Order_OD_SOZ: '',
          CL_Order_OS_SOZ: '',
          CL_Order_OD_THIN: '',
          CL_Order_OS_THIN: ''
        },
        S_OP_Model: "CL_Order180626",
        S_OP_Time: "",
        S_OP_Type: "CL_Order",
        S_OP_XML: ""
      },

      iscrt: '梦戴维',

      sdbz: [
        {
          value: 'IV-AP',
          label: 'IV-AP'
        },
        {
          value: 'IV-DF',
          label: 'IV-DF'
        },
        {
          value: 'IV-M',
          label: 'IV-M'
        },
        {
          value: 'DV',
          label: 'DV'
        },
        {
          value: 'RGP',
          label: 'RGP'
        },
        {
          value: 'CK-MZ',
          label: 'CK-MZ'
        },
        {
          value: 'CK-BD-K',
          label: 'CK-BD-K'
        },
        {
          value: '',
          label: ''
        }
      ],
      hqm: [
        {
          value: '1.5D',
          label: '1.5D'
        },
        {
          value: '2.0D',
          label: '2.0D'
        },
        {
          value: '2.5D',
          label: '2.5D'
        },
        {
          value: '',
          label: ''
        }
      ],
      ys: [
        {
          value: '绿',
          label: '绿'
        },
        {
          value: '蓝',
          label: '蓝'
        },
        {
          value: '',
          label: ''
        },
      ],
      jpcl: [
        {
          value: 'XO-P',
          label: 'XO-P'
        },
        {
          value: 'XO',
          label: 'XO'
        },
        {
          value: '',
          label: ''
        },
      ],
    }
  },
  created() {
    let i = 0.5
    while (i <= 3) {
      let item = i.toString()
      if (item.length == 3 && item[item.length] != 5) item = item + '0'
      if (item.length == 1 && item[item.length-1] != 5) item = item + '.00'
      this.tjyz.push({
        value: item,
        label: item
      })
      i+=0.25
    }
    this.tjyz.push({
      value: '',
      label: ''
    })
    let lg = this.$loading({
      text: '加载中...'
    })
    this._api.corneaContact.getCorneaOrder()
        .then(res => {
          lg.close()
          if (res.GetListResult && res.GetListResult.length > 0) {
            this.postData = res.GetListResult[0]
            this.postData.S_OP_Json = pf.decoding(this.postData.S_OP_Json)
          }else {
            this.postData.S_OP_ChooseId = this.$store.state.physicianVisits.xzConsulting
            this.postData.S_OP_Json.CL_Order_ChooseId = this.$store.state.physicianVisits.xzConsulting
            this.postData.S_OP_CustomerId = this.$store.state.users.CsUser.CustomerId
            let obj = JSON.parse(JSON.stringify(this.$store.state.corneaContact.jqtjsj))
            if (obj) {
              if (obj.S_OP_Json.S_OP_CG_brand === 'CRT') {
                this.postData.S_OP_Json.CL_Order_OD_type1 = obj.S_OP_Json.S_OP_CG_Try_TypeOD
                this.postData.S_OP_Json.CL_Order_OS_type1 = obj.S_OP_Json.S_OP_CG_Try_TypeOS
                this.postData.S_OP_Json.CL_Order_OD_type2 = obj.S_OP_Json.S_OP_CG_Try_DingWeiHuOD
                this.postData.S_OP_Json.CL_Order_OS_type2 = obj.S_OP_Json.S_OP_CG_Try_DingWeiHuOS
                this.postData.S_OP_Json.CL_Order_OD_DCurve = obj.S_OP_Json.S_OP_CG_Try_JiangFuOD
                this.postData.S_OP_Json.CL_Order_OS_DCurve = obj.S_OP_Json.S_OP_CG_Try_JiangFuOS

                this.postData.S_OP_Json.CL_Order_OD_JCurve = obj.S_OP_Json.S_OP_CG_Try_DIAOD
                this.postData.S_OP_Json.CL_Order_OS_JCurve = obj.S_OP_Json.S_OP_CG_Try_DIAOS
                this.postData.S_OP_Json.CL_Order_OD_DIA = obj.S_OP_Json.S_OP_CG_Try_ColorOD
                this.postData.S_OP_Json.CL_Order_OS_DIA = obj.S_OP_Json.S_OP_CG_Try_ColorOS
                this.postData.S_OP_Json.CL_Order_OD_HuanQuMian = obj.S_OP_Json.S_OP_CG_Try_JiHuOD
                this.postData.S_OP_Json.CL_Order_OS_HuanQuMian = obj.S_OP_Json.S_OP_CG_Try_JiHuOS
                this.postData.S_OP_Json.CL_Order_OD_Color = obj.S_OP_Json.S_OP_CG_Try_HuanQuMianOD
                this.postData.S_OP_Json.CL_Order_OS_Color = obj.S_OP_Json.S_OP_CG_Try_HuanQuMianOS
                this.postData.S_OP_Json.CL_Order_OD_material = obj.S_OP_Json.S_OP_CG_Try_EValueOD
                this.postData.S_OP_Json.CL_Order_OS_material = obj.S_OP_Json.S_OP_CG_Try_EValueOS
              }else {
                this.postData.S_OP_Json.CL_Order_OD_DCurve = obj.S_OP_Json.S_OP_CG_Try_DingWeiHuOD
                this.postData.S_OP_Json.CL_Order_OS_DCurve = obj.S_OP_Json.S_OP_CG_Try_DingWeiHuOS
                this.postData.S_OP_Json.CL_Order_OD_JCurve = obj.S_OP_Json.S_OP_CG_Try_JiangFuOD
                this.postData.S_OP_Json.CL_Order_OS_JCurve = obj.S_OP_Json.S_OP_CG_Try_JiangFuOS
                this.postData.S_OP_Json.CL_Order_OD_DIA = obj.S_OP_Json.S_OP_CG_Try_DIAOD
                this.postData.S_OP_Json.CL_Order_OS_DIA = obj.S_OP_Json.S_OP_CG_Try_DIAOS
                this.postData.S_OP_Json.CL_Order_OD_HuanQuMian = obj.S_OP_Json.S_OP_CG_Try_HuanQuMianOD
                this.postData.S_OP_Json.CL_Order_OS_HuanQuMian = obj.S_OP_Json.S_OP_CG_Try_HuanQuMianOS
                this.postData.S_OP_Json.CL_Order_OD_Color = obj.S_OP_Json.S_OP_CG_Try_ColorOD
                this.postData.S_OP_Json.CL_Order_OS_Color = obj.S_OP_Json.S_OP_CG_Try_ColorOS
                this.postData.S_OP_Json.CL_Order_OD_type1 = obj.S_OP_Json.S_OP_CG_Try_TypeOD
                this.postData.S_OP_Json.CL_Order_OS_type1 = obj.S_OP_Json.S_OP_CG_Try_TypeOS


                this.postData.S_OP_Json.CL_Order_OD_adjustFactor = obj.S_OP_Json.S_OP_CG_Try_adjustFactorOD
                this.postData.S_OP_Json.CL_Order_OS_adjustFactor = obj.S_OP_Json.S_OP_CG_Try_adjustFactorOS





              }
              this.postData.S_OP_Json.CL_Order_Type = obj.S_OP_Json.S_OP_CG_brand
            }
          }
        })


    this.cshTime('contact_product')

    this.getList()
    // this._api.corneaContact.getCorneaShop()
    // .then(res => {
    //   console.log(res)
    // }),

  },
  methods: {
    save() {

      if ( this.$store.state.Time.timeObj.contact_product.ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.contact_product.ENTER)/1000 + Number(this.$store.state.Time.timeObj.contact_product.EXIT)
        this._api.publicApi.addTime('contact_product,contact_prescriptionData,contact_params,contact_date', times)
        this.jsTime('contact_product', 1)
      }

      let type = ''
      if (this.value) type = this.cplx[Number(this.value)].label
      this.postData.S_OP_Json.CL_Order_Type = type
      let datas = JSON.parse(JSON.stringify(this.postData))
      datas.S_OP_Json = pf.conversionJson(datas.S_OP_Json)
      let lig = Loading.service({
        text: '保存中...',
        background: 'rgba(0, 0, 0, 0.8'
      })
      axios.post(pf.getUrl('S_ALL_Normal_Save'), {
        "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: datas
      })
          .then(res => {
            // console.log(res)
            lig.close()
            this.$store.commit('corneaContact/upjqyptbsj', {
              ysod: this.postData.S_OP_Json.CL_Order_OD_Color,
              ysos: this.postData.S_OP_Json.CL_Order_OS_Color,
              dwhod: this.postData.S_OP_Json.CL_Order_OD_DCurve,
              dwhos: this.postData.S_OP_Json.CL_Order_OS_DCurve,
              jfod: this.postData.S_OP_Json.CL_Order_OD_JCurve,
              jfos: this.postData.S_OP_Json.CL_Order_OS_JCurve,
            })
            if (res.UpdateResult) {
              this.$alert('保存成功!', '提示')
              if (this.value1) this.add(1, res.S_OP_ID)
              if (this.value2) this.add(2, res.S_OP_ID)
            }else {
              this.$alert('保存失败!', '提示')
            }
          })
    },
    tb() {
      let datas = this.$store.state.eyeTest.refractiveData.S_OP_Json
      this.$set(this.postData.S_OP_Json, 'CL_Order_OD_DS', datas.S_OP_R_SS_DSOD)
      this.$set(this.postData.S_OP_Json, 'CL_Order_OD_DC', datas.S_OP_R_SS_DCOD)
      this.$set(this.postData.S_OP_Json, 'CL_Order_OD_X', datas.S_OP_R_SS_XOD)

      this.$set(this.postData.S_OP_Json, 'CL_Order_OS_DS', datas.S_OP_R_SS_DSOS)
      this.$set(this.postData.S_OP_Json, 'CL_Order_OS_DC', datas.S_OP_R_SS_DCOS)
      this.$set(this.postData.S_OP_Json, 'CL_Order_OS_X', datas.S_OP_R_SS_XOS)
    },

    zh(key, type=0) {
      let str = this.postData.S_OP_Json[key]
      if (str !== '' && Number(str) > 0) {
        if (type) {
          if (this.postData.S_OP_Json[key].indexOf("+") < 0) {
            this.postData.S_OP_Json[key] = "+" + str
          }
        }else {
          this.postData.S_OP_Json[key] = str.toString()
        }
      }
    },

    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    },

    ysqm() {
      this._api.expertClinic.ysqm('CLOrderDoctorSign')
          .then(res => {
            if (typeof res === 'object') {
              if (res.InsertResult) {
                this.$store.commit('physicianVisits/upIsqm', false)
                this.$alert('提交签名成功', '提示')
              }else {
                this.$alert('提交签名失败', '提示')
              }
            }
          })
    },

    getList() {
      this._http.post(this._getUrl('priceSortList'), {
        // SearchString: `${jk}:${str}`,
        "SearchCondition": {
          "ShopId": this.$store.state.bases.shop.ShopId,//实际ShopId
          "IndexMin": 0,
          "IndexMax":0,
          "SortKey": 'rgp',//镜片：lens；镜框：frame
          // "SortKey": 'exam',//镜片：lens；镜框：frame
          // "ProductName": this.cpmc,
          // "BrandId": this.ppid,//若有筛选品牌则填入该行，多个BrandId用英文逗号拼接
        },
        "ReturnInfo": {
          "Product": {
            "Brand": 1
          }
        },
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        }
      })
          .then(res => {
            if (res.GetRelationListResult) {
              // console.log(res.GetRelationListResult)
              this.cpmcdata= [...res.GetRelationListResult]
              let arr = []
              for (let i = 0; i < res.GetRelationListResult.length; i++) {
                arr.push({
                  value: res.GetRelationListResult[i].ProductDetail.Product.ProductId,
                  label: res.GetRelationListResult[i].ProductDetail.Product.ProductName
                })
              }
              arr.push({
                value: '',
                label: ''
              })
              this.cpmc = [...arr]
            }
          })
    },

    add(val, id) {
      let pic = 0
      for (let i = 0; i < this.cpmcdata.length; i++) {
        if (val == 1) {
          if (this.value1 == this.cpmcdata[i].ProductDetail.Product.ProductId) {
            pic = this.cpmcdata[i].ProductDetail.Product.ProductOfficalPrice
          }
        }
        if (val == 2) {
          if (this.value2 == this.cpmcdata[i].ProductDetail.Product.ProductId) {
            pic = this.cpmcdata[i].ProductDetail.Product.ProductOfficalPrice
          }
        }
      }
      this._http.post(this._getUrl('frameDetail_addToCart'), {
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        },
        "o": {
          "CHPChooseId": this.$store.state.physicianVisits.xzConsulting,//实际ChooseId
          "CHPProductId": val==1?this.value1:this.value2,//实际ProductId
          "CHPAmount":1,//最终实际数量，如果之前已加入1件，后来再增加到3件，则此处传3
          "CHPS_OP_ALL_Id": id,//绑定的处方的S_OP_ALLId，添加时此处可以先填写全0的Guid，后续再修改，镜片类商品需带有处方的S_OP_ALLId才可以下单
          "CHPLeftRightEyes": val==1?'OD':'OS',//左右眼或双眼，取值右眼：“OD”\左眼“OS”\双眼“OU”。添加时此处可以先填写空字符串，后续再修改，镜片类商品需带有左右眼信息可以下单
          "CHPProductSortGroup": "rgp",//等同于Product.ProductSortKey
          "CHPPrescriptionData":"",//药品类该项需要填入这个结构的字符串，其它类别填空字符串
          "CHPPrice": pic,//价格，暂不清楚是单价还是总价，取ExpertProduct.EPPrice或ShopProduct.SPPrice或Product.ProductOfficalPrice
        },
      })
          .then(res => {
            if (res.InsertResult) {
              // this.$alert('加入产品成功！', '提示')
            }else {
              this.$alert('加入产品失败！', '提示')
            }
          })
    },


    getChooseList(id) {
      this._api.expertClinic.getChooseList(id, 'rgp')
          .then(res => {
            console.log(res)
            if (res.GetListResult && res.GetListResult.length) {
              for (let i = 0; i < res.GetListResult.length; i++) {
                if (res.GetListResult[i].CHPLeftRightEyes === 'OD') {
                  this.value1= res.GetListResult[i].CHPProductId
                }
                if (res.GetListResult[i].CHPLeftRightEyes === 'OS') {
                  this.value2 = res.GetListResult[i].CHPProductId
                }
              }
            }
          })
    }
  },
  beforeDestroy() {
    if (this.$store.state.Time.timeObj.contact_product.ENTER != 0) {
      this.jsTime('contact_product')
    }
  }
}
</script>

<style scoped lang="scss">
.corneaOrder {
  width: 100vw;
  height: 87vh;
  overflow-y: auto;
}
.top {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 .8rem;
  margin-top: .5rem;
  padding-bottom: .3rem;
  .yj {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: .2rem 0;
    p {padding-right: 5px; width: 2em;}
  }
  .sr {
    margin-left: 5px;
    width: 10vw;
    margin-right: 6vw;
  }
  .srs {width: 16vw}
  border-bottom: 1px solid #40a7f6;
}

.mar-20 {margin-top: 30px}
.padding-20{padding-top: 15px;}
.srA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  .content-sr {
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      width: 8vw;
      margin-right: .4rem;
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
    }
    li:nth-child(1) {
      width: 3vw;
      text-align: left;
      margin-right: 5px;
    }
  }
  .tj {
    input {
      display: block;
      width: 100%;
      outline: none;
      border: 1px solid #000000;
      border-radius: 5px;
      padding: 5px 0;
      text-align: center;
      margin-top: 21px;
    }
  }
  .last {
    margin-left: .5rem;
    margin-top: 32px;
  }
}
.dx {
  ul {
    li {
      width: 10em !important;
    }
    li:nth-child(1) {width: 3vw!important;}
  }
}
.cf {
  padding-left: .8rem;
  padding-bottom: .3rem;
  h3 {text-align: left; padding-top: .5rem}
  border-bottom: 1px solid #40a7f6;
}

.time {
  padding: .3rem 0;
  display: flex;
  align-items: center;
  padding-left: .8rem;
  .rq {
    width: 13vw;
    margin-left: 5px;
  }
  .mag-7 {margin-right: .7rem}
}

.bottom {
  padding-top: .3rem;
  padding-bottom: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
